<template>
  <b-dropdown
    size="sm"
    variant="link"
    right
    no-caret
    menu-class="custom-dropdown dropdown-menu pa-0 ma-0"
  >
    <template #button-content>
      <img v-if="image && image !==''" :src="image" class="profile-icon" alt="account-icon-v2" />
      <div v-else class="profile-icon profile-empty" alt="account-icon-v2" >
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 18V15.5798C19 14.2961 18.4996 13.0649 17.6088 12.1572C16.718 11.2495 15.5098 10.7395 14.25 10.7395H4.75C3.49022 10.7395 2.28204 11.2495 1.39124 12.1572C0.500445 13.0649 0 14.2961 0 15.5798V18" fill="url(#paint0_linear_3846_1071)"/>
          <path d="M9.5 9.68066C12.1234 9.68066 14.25 7.51357 14.25 4.84033C14.25 2.16709 12.1234 0 9.5 0C6.87665 0 4.75 2.16709 4.75 4.84033C4.75 7.51357 6.87665 9.68066 9.5 9.68066Z" fill="url(#paint1_linear_3846_1071)"/>
          <defs>
          <linearGradient id="paint0_linear_3846_1071" x1="9.5" y1="1.22727" x2="9.37315" y2="19.5211" gradientUnits="userSpaceOnUse">
          <stop stop-color="white"/>
          <stop offset="1" stop-color="white" stop-opacity="0"/>
          </linearGradient>
          <linearGradient id="paint1_linear_3846_1071" x1="9.5" y1="1.22727" x2="9.37315" y2="19.5211" gradientUnits="userSpaceOnUse">
          <stop stop-color="white"/>
          <stop offset="1" stop-color="white" stop-opacity="0"/>
          </linearGradient>
          </defs>
        </svg>
      </div>
    </template>
    <b-dropdown-item
      v-for="(panel, index) in painelItems"
      :key="index"
      @click="goToPanel(panel)"
      v-show="index < 3"
    >
      <section class="panel-items apply-opacity">
        <img
          :src="panel.logo"
          :alt="`Logo do curso ${panel.name}`"
          v-if="panel.logo"
          width="34"
          height="34"
          class="panel-logo"
        />
        <div class="icon-panel" v-else>
          <img src="/account_icon_v2.svg" :alt="`panel-${index}`" />
        </div>
        <span class="menu-panel-item-text pt-1">
          {{ panel.name }}
        </span>
        <ExplodeHorizontal />
      </section>
    </b-dropdown-item>
    <b-dropdown-item @click="$root.$emit('show-panel-modal-v2')">
      <section class="panel-items">
        <div class="icon-panel no-background">
          <img src="/panel.svg" alt="panel icon" class="menu-icons" />
        </div>
        {{ $t("menu.all_panels") }}
      </section>
    </b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>

    <b-dropdown-item
      v-show="item.show"
      v-for="item in menuItems.filter((item) => item.show)"
      :key="`menu-item-${item.id}`"
      @click="item.action"
    >
      <section class="panel-items">
        <div class="icon-panel no-background">
          <img :src="item.icon" :alt="item.icon" class="menu-icons" />
        </div>
        {{ item.nome }}
      </section>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import Cookies from 'js-cookie';
import ExplodeHorizontal from '../spacer/index.vue';
import moment from 'moment';
import 'moment/locale/pt-br';
// Services
import NotifyService from '@/services/resources/NotifyService';
const serviceNotify = NotifyService.build();

export default {
  props: ['image'],
  data() {
    return {
      client: {
        width: 0,
      },
      showMenuItems: false,
      showNotifications: false,
      painelItems: [],
      notifications: [],
      themeFixedPainel: false,
      showHelperMenu: false,
      typeText: '',
      permission2: false
    };
  },
  components: {
    ExplodeHorizontal,
  },
  computed: {
    allSites() {
      return this.$store.getters.allSites;
    },
    currentAuth() { 
      return this.$store.getters.currentAuth;
    },
    isMobile() {
      return this.client.width <= 576;
    },
    theme() {
      return this.$store.state.globalSettings.theme;
    },
    themeIsDark() {
      return this.theme === 'dark';
    },
    themeIsLight() {
      return this.theme === 'light';
    },
    menuItems() {
      return [
        {
          id: 1,
          show: this.themeFixedPainel && this.themeIsDark,
          icon: 'sun.svg',
          nome: this.$t("menu.light_mode"),
          action: () => this.changeTheme('light'),
        },
        {
          id: 2,
          show: this.themeFixedPainel && this.themeIsLight,
          icon: 'sun.svg',
          nome: this.$t("menu.dark_mode"),
          action: () => this.changeTheme('dark'),
        },
        {
          id: 3,
          show: true,
          icon: 'account.svg',
          path: 'minha-conta',
          nome: this.$t("minha_conta.titleV2"),
          action: () => this.changeRoute('/minha-conta'),
        },
        {
          id: 4,
          show: this.showHelperMenu,
          icon: 'help.svg',
          nome: this.$t("helper.help_center"),
          action: () => this.changeRoute('/ajuda'),
        },
        {
          id: 5,
          show: this.permission2,
          icon: 'certificates-mobile.svg',
          nome: `${this.$t("menu.ver_como")} ${this.typeText}`,
          action: () => this.updateTypeView(),
        },
        {
          id: 6,
          show: true,
          icon: 'logout.svg',
          nome: this.$t("menu.logout.logout"),
          action: () => this.logout(),
        },
      ];
    },
    hasNotifications() {
      return !!this.notifications.length;
    },
    reverseNotifications() {
      const items = [...this.notifications];
      return items;
    },
    formatDate() {
      return function (date) {
        moment.locale('pt-br');
        return moment(date).fromNow();
      };
    },
  },
  methods: {
    goToPanel(panel) {
      this.$store.dispatch('blogSelected', panel);
    },
    click() {
      setTimeout(() => {
        document.querySelector('.btn-menu').click();
      }, 500);
    },
    changeRoute(path) {
      this.$router.push(path);
    },
    logout() {
      this.$store.dispatch('logoutRequest').catch(function () {});
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    changeTheme(theme) {
      const t = !!theme ? theme : 'light'
      Cookies.set('themeClub', t, { expires: 365 });
      this.$store.commit('ClubTheme', t);
      setTimeout(() => {
        this.insertTheme();
      }, 500);
    },
    insertTheme() {
      const d = document;
      let theme = Cookies.get('themeClub');
      if (theme === undefined || theme === null || theme === 'undefined') {
        d.documentElement.setAttribute('data-theme', 'light');
      } else {
        d.documentElement.setAttribute('data-theme', theme);
      }
      this.$root.$emit('loadTheme');
      this.$root.$emit('loadsettheme');
    },
    getPanels() {
      Cookies.set('memberAuth', this.currentAuth, { expires: 365 });
      this.painelItems = this.allSites;
    },
    toggle(from = 'menu') {
      if (from === 'menu') this.showMenuItems = !this.showMenuItems;
      else this.showNotifications = !this.showNotifications;
    },
    async getNotifications() {
      await serviceNotify
        .search()
        .then((resp) => {
          this.notifications = resp.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async clicked(notification) {
      await serviceNotify
        .postID({ id: notification.id, viewed: true })
        .then(() => {
          this.getNotifications();
        })
        .finally(() => {
          this.$router.push(
            `/curso/${notification.course_id}/modulo/${notification.module_id}/aula/${notification.lesson_id}?c_id=${notification.lesson_comment_id}`,
          );
        });
    },
    async getThemeFixed() {
      let resp = await this.$store.dispatch("fetchMetas", ["theme_fixed_painel"])
      if (resp.theme_fixed_painel == 'on') {
        this.themeFixedPainel = false;
      }
      if (resp.theme_fixed_painel == 'off') {
        this.themeFixedPainel = true;
      }
    },
    async getMetaHelper() {
      let resp = await this.$store.dispatch("fetchMetas", ["helper_data"])
      this.showHelperMenu = resp.helper_data !== 'off';
    },
    getTypeView() {
      let typeView = Cookies.get("type_student");
      if (typeView === undefined || typeView === "0") {
        this.typeText = "Aluno";
      } else {
        this.typeText = "Admin";
      }
    },
    updateTypeView() {
      let typeView = Cookies.get("type_student");
      if (typeView === undefined || typeView === "0") {
        Cookies.set("type_student", 1);
      } else {
        Cookies.set("type_student", 0);
      }
      setTimeout(() => {
        location.reload();
      }, 1000);
    },
    getPermission2() {
      let permission2 = Cookies.get("access_level");
      if (permission2 === "admin" || permission2 === "owner") {
        this.permission2 = true;
      } else {
        this.permission2 = false;
      }
    },
  },
  created() {
    this.getTypeView();
    this.getPermission2();
    this.getPanels();
    this.getNotifications();
    this.getMetaHelper();
    window.addEventListener(
      'resize',
      () => (this.client.width = window.outerWidth),
    );
    this.handleResize();
    this.getThemeFixed();
  },
  mounted() {
    this.$root.$on('helper-updated', () => {
      this.getMetaHelper();
    });
  },
};
</script>

<style lang="scss" scoped>
.menus {
  display: flex;
  justify-content: flex-end;
  min-width: 100px;
  gap: 15px;
}
.icon-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 35px;
  min-height: 35px;
  border-radius: 10px;

  &:not(&.no-background) {
    background-color: var(--maincolor); /* Cor de fundo do ícone */
  }
}
.menu {
  /* Propriedades de estilo básicas do ícone */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  background-color: var(--maincolor); /* Cor de fundo do ícone */
  border-radius: 10px; /* Bordas arredondadas para criar um ícone circular */
  cursor: pointer;
  z-index: 10;
  /* Transições para suavizar a animação */
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.menu:hover {
  /* Propriedades de estilo ao passar o cursor sobre o ícone */
  box-shadow: 0 0 10px var(--maincolor); /* Sombra amarela para simular o brilho */

  /* Animação pulsante para adicionar efeito de brilho */
  /* animation-name: transform;
  animation-duration: 3s;
  animation-fill-mode: ease-in-out; */

  /* Outros estilos se necessário */
}

.menu-items-panels-container {
  position: absolute;
  right: 12px;
  border-radius: 20px;
  margin-top: 12px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  min-width: 257px;
  z-index: 20;
  background: var(--background2-v2);
  color: var(--fontcolor-v2);
}

.menu-panel-item-text {
  white-space: nowrap;
}
/* .menu-item {
  min-width: 257px;
  display: flex;
  width: 100%;
  padding: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 99;
} */
.panel-item {
  min-width: 257px;
  display: flex;
  cursor: pointer;
  width: 100%;
  padding-bottom: 10px;
  padding-left: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 99;
}

.menu-item {
  display: flex;
  width: 100%;
  height: 40px;
  flex-wrap: nowrap;
  align-items: center;
  padding-bottom: 15px;
  padding-left: 28px;
  cursor: pointer;
}

.divider {
  height: 1px;
  display: flex;
  width: 80%;
  margin-top: 6px;
  margin-bottom: 21px;
  margin-right: 10%;
  margin-left: 10%;
  border: 0.2px solid var(--fontcolor2-v2);
}

.notification {
  @extend .menu;
  background-color: var(--maincolor);
  position: relative;

  &:hover {
    box-shadow: 0 0 10px var(--maincolor);
  }

  &.has-notification::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: var(--maincolor);
    top: -3px;
    right: -3px;
    border-radius: 50%;
    z-index: 999999999999999999999;
  }

  .header {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
  }

  .count {
    text-align: center;
    color: var(--maincolor);
    background: rgba(1, 202, 166, 0.1);
    border-radius: 2px;
    font-family: Montserrat;
    font-weight: 600;
    line-height: 18px;
    padding: 5px;
    text-align: center;
    border-radius: 3px;
  }
}

.panel-items {
  display: flex;
  align-items: center;
  gap: 15px;
}

.menu-icons {
  width: 18px;
  height: 18px;
}

.grid-notification {
  display: grid;
  grid-gap: 5px;
  .a {
    grid-area: a;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }

    &.no-has-picture {
      p {
        margin: 0;
        padding: 0;
        font-weight: 600;
        font-size: 20px;
        display: flex;
        align-items: center;
        color: #ffffff;
        text-decoration: none;
      }
      background: var(--maincolor);
    }

    &.not-viewed::after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      top: 0;
      left: 0;
      background: var(--maincolor);
      border-radius: 50%;
      z-index: 999999999999999999999;
    }
  }
  .b {
    grid-area: b;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    p {
      display: flex;
      flex-wrap: wrap;
      gap: 3px;
    }
  }
  .c {
    grid-area: c;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #9a9a9a;
  }
  .d {
    grid-area: d;
    max-width: 355px;
    background: var(--closes-bg);
    border-radius: 10px;
    padding: 10px;

    display: flex;
    flex-wrap: wrap;

    p {
      margin: 0;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: break-spaces;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  grid-template-areas:
    'a b b b'
    'a c c c'
    'a d d d';
}

.panel-logo {
  border-radius: 5px;
}
.profile-icon {
  height: 28px;
  width: 28px;
  margin-bottom: 10px;
  border-radius: 50%;
}
.profile-empty {
  background: var(--maincolor);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

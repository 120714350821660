<template>
  <div class="bottom-navitgation">
    <div class="bottom-navitgation-item-content">
      <span
        v-for="item in menuItems"
        :key="item.path"
        @click="defineRoute(item)"
        :class="`bottom-navitgation-item bottom-navitgation-item-${isActive(item.path)}-${theme}`"
      >
        <SvgIcon :key="item.path" :routeCompare="$route.path" :path="item.path" v-if="item.name !== 'Conta'"></SvgIcon>
        <AvulseMenu :image="picProfile" v-if="item.path === 'minha-conta'" />
        <!-- <img  class="profile-icon" :src="picProfile" alt="conta"> -->
        <span :class="{ 'account-text': item.name === 'Conta' }">
          {{ item.name }}
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import AvulseMenu from '@/components/V2/menu/avulseMenu.vue';
import MemberService from "@/services/resources/MemberService";
import SvgIcon from "./SvgIcon.vue";

const serviceMember = MemberService.build();
export default {
  components: {
    SvgIcon,
    AvulseMenu
  },
  computed: {
    theme() {
      return this.$store.state.globalSettings.theme;
    }
  },
  data() {
    return {
      menuItems: [
        { icon: 'home-mobile.svg', path: "home", name: this.$t("navbar.start") },
        // { icon: 'search-mobile.svg', path: "search", name: "Buscar" },
        { icon: 'certificates-mobile.svg', path: "certificates", name: this.$t("menu.certificado") },
        // { icon: 'download-mobile.svg', path: "downloads", name: "Downloads" },
        { icon: "", path: "minha-conta", name: this.$t("general.account") },
      ],
      picProfile: ''
    };
  },
  methods: {
    defineRoute(item) {
      if(item.path === 'minha-conta') {
        this.$root.$emit('show-mobile-menu')
      } else {
        this.$router.push(`/${item.path}`);
      }
    },
    getPicProfile() {
      serviceMember
        .read("/meta")
        .then((resp) => {
          this.picProfile = resp.picture;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    isActive(path) {
      const isActive = `/${path}` === this.$route.path;
      return isActive ? "active" : "unactive";
    },
    toggle() {
      this.showMenuItems = !this.showMenuItems;
    },
  },
  created() {
    this.getPicProfile();
  }
};
</script>

<style scoped>
.bottom-navitgation {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  flex-wrap: nowrap;
  height: 85px;
  background-color: var(--background2-v2);
}
.bottom-navitgation-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Montserrat;
  font-size: 9px;
  font-weight: 400;
  /* line-height: 18px; */
  letter-spacing: 0em;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
}
.bottom-navitgation-item-active-light {
  color: #16171a !important;
}
.bottom-navitgation-item-active-dark {
  color: #fff !important;
}
.bottom-navitgation-item-unactive-light {
  color: rgba(70, 70, 71, 0.5) !important;
}
.bottom-navitgation-item-unactive-dark {
  color: rgba(255, 255, 255, 0.5) !important;
}
.bottom-navitgation-item-content {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}
.bottom-icon {
  height: 21px;
  margin-bottom: 15px;
}
.profile-icon {
  height: 28px;
  width: 28px;
  margin-bottom: 8px;
  border-radius: 50%;
}
.account-text {
  position: relative;
  top: -7px;
}
</style>

<template>
  <svg :class="{ 'menu-active': routeCompare == `/${path}`, 'menu-item-unactive': routeCompare != `/${path}` }" v-if="path === 'home'" width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.33333 22V11.5H13.6667V22M1 8.35L10.5 1L20 8.35V19.9C20 20.457 19.7776 20.9911 19.3817 21.3849C18.9858 21.7787 18.4488 22 17.8889 22C12.1178 22 8.88219 22 3.11111 22C2.55121 22 2.01424 21.7787 1.61833 21.3849C1.22242 20.9911 1 20.457 1 19.9V8.35Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  <svg :class="{ 'menu-active': routeCompare == `/${path}`, 'menu-item-unactive': routeCompare != `/${path}` }" v-else-if="path === 'certificates'" width="16" height="25" viewBox="0 0 16 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.21 14.4759L3 24L8 20.8636L13 24L11.79 14.4655M15 8.31818C15 12.3599 11.866 15.6364 8 15.6364C4.13401 15.6364 1 12.3599 1 8.31818C1 4.27646 4.13401 1 8 1C11.866 1 15 4.27646 15 8.31818Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
      required: true
    },
    routeCompare: {
      type: String,
      required: true
    }
  },
};
</script>

<style lang="scss" scoped>
.menu-active {
  stroke: var(--maincolor) !important;
}
.menu-item-unactive {
  stroke: var(--fontcolor2-v2) !important;
}
svg {
  height: 21px;
  margin-bottom: 15px;
}
</style>